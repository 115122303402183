<template>
  <!-- Boards Public View -->
  <div class="w-full h-full relative bg-none">
    <!-- Whitelabel -->
    <transition
      name="fadeHeader"
      mode="out-in"
    >
      <WhiteLabelHeader
        v-if="adTeam !== null"
        :board-team="adTeam"
        :shared-by="sharedBy"
        :board="board"
        :ads="advertisements"
        share-type="ad"
      />
      <WhiteLabelHeader
        v-else-if="adTeam !== null && !adTeam?.whiteLabel"
        :board-team="adTeam"
        :shared-by="sharedBy"
        :show-default="true"
        share-type="ad"
      />
    </transition>

    <MainLayout
      aditional-classes="ml-4"
      style="height: calc(100vh - 70px); width: calc(100vw - 30px); z-index: 99; position:relative"
    >
      <template #content>
        <!-- Loading State -->
        <BaseLoadingSpinner
          v-if="loadingAdvertisements || loadingSubscription"
          class="mt-24"
        />

        <!-- Empty State -->
        <div
          v-else-if="!advertisements.length"
          class="flex flex-col items-center mt-20"
        >
          <img
            src="../../assets/images/empty-single-library.gif"
            class="border border-gray-400 rounded mx-auto object-cover"
            style="width: 400px; height: 300px"
          >

          <div class="text-lg font-bold my-4">
            No Ad to Show...
          </div>
        </div>

        <div
          v-else-if="!userSubscribed"
          class="flex flex-col items-center mt-20"
        >
          <div class="text-lg font-bold my-4">
            The owner of this ad’s foreplay account has been cancelled
          </div>
        </div>

        <!-- Ads List -->
        <AdvertisementList
          v-else
          hide-boards
          :advertisements="advertisements"
          :shared-board="true"
        />
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import WhiteLabelHeader from '../../components/account/whiteLabel/WhiteLabelHeader.vue'
export default {
  name: 'AdsPublicView',
  components: {
    AdvertisementList,
    MainLayout,
    WhiteLabelHeader
  },
  data () {
    return {
      board: {},
      advertisements: [],
      loadingAdvertisements: true,
      loadingSubscription: true,
      userSubscribed: true,

      adTeam: null,
      sharedBy: null
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    // Since there is no navbar, we need to initialize some Vuex state here
    await this.fetchBoards()
    await this.fetchFolders()
    await this.SET_STARRED_BOARDS(
      this.getBoards.filter((board) =>
        this.getUser.starredBoards?.includes(board.id)
      )
    )

    await this.fetchAdvertisements()
    await this.checkIfUserSubscribed()
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapMutations('BoardsModule', ['SET_BOARDS']),
    ...mapMutations('AuthModule', ['SET_STARRED_BOARDS']),

    async checkIfUserSubscribed () {
      // Temp fix
      let isUserSubscribed = false
      if (this.advertisements[0]?.created_by) {
        isUserSubscribed = await FirebaseAPI.Users.checkUserSubscription(
          this.advertisements[0].created_by
        )
      }

      // add team check
      let isTeamSubscribed
      if (this.advertisements[0]?.teamId && !isUserSubscribed) {
        isTeamSubscribed = await FirebaseAPI.Users.checkTeamSubscription(
          this.advertisements[0].teamId
        )
      }

      // Temp fix (Jan 20th, 2025)
      let sharedUserSubscribed
      if (!isUserSubscribed && !isTeamSubscribed) {
        sharedUserSubscribed = await FirebaseAPI.Users.checkUserSubscription(
          this.$route.query.user
        )
      }

      if (isUserSubscribed === false) {
        // Track Trial Ended
        window.analytics.track('Trial Ended', {
          trialEnded: true
        })
      }
      this.userSubscribed = isUserSubscribed || isTeamSubscribed || sharedUserSubscribed
      this.loadingSubscription = false
    },
    // Go to Signup
    goToSignup () {
      this.SET_BOARDS([])
      this.$router.push({ name: 'LoginView' })
    },
    // Fetch Advertisements
    async fetchAdvertisements () {
      try {
        let ad = await FirebaseAPI.Advertisements.get(this.$route.params.id)

        // Make share backwards compatible
        if (!ad.type) {
          ad = await FirebaseAPI.Advertisements.getByID(this.$route.params.id)
        }

        this.advertisements = [ad]
        this.sharedBy = await FirebaseAPI.Users.getById(this.$route.query.user)
        this.adTeam = await FirebaseAPI.Teams.getByID(ad?.teamId)
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
      }
    }
  }
}
</script>
<style scoped>
.fadeHeader-enter-active,
.fadeHeader-leave-active {
  transition: all 0.2s ease-out;
  opacity: 1;
}

.fadeHeader-enter,
.fadeHeader-leave-to {
  opacity: 0;
}
</style>
